/*---- 17. Sidebar style  ------*/

.sidebar-style {
  position: relative;
  margin-bottom: 5px;
  @media #{$lg-layout} {
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$md-layout} {
    margin-top: 30px;
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$xs-layout} {
    margin-top: 30px;
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$lg-layout} {
    &.ml-30 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    margin-top: 30px;
    &.ml-30 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    margin-top: 30px;
    &.ml-30 {
      margin-left: 0;
    }
  }
}

.sidebar-widget {
  h4.pro-sidebar-title {
    font-size: 16px;
    font-weight: 500;

    margin: 0;

    color: #333;
  }
  .sidebar-widget-list {
    ul {
      li {
        position: relative;

        align-items: center;

        padding: 0 0 15px;
        &:last-child {
          padding: 0 0 0;
        }
        .sidebar-widget-list-left {
          input {
            position: absolute;
            z-index: 999;

            width: auto;

            cursor: pointer;

            opacity: 0;
            &:checked ~ .checkmark {
              border: 2px solid #fa6bff;
              background-color: #fa6bff;
            }
            &:checked ~ .checkmark::after {
              display: block;
            }
          }
          .checkmark {
            position: absolute;
            left: 0;

            width: 15px;
            height: 15px;

            border: 2px solid #888888;
            border-radius: 3px;
            background-color: #fff;
            &::after {
              position: absolute;

              display: none;

              content: "";
            }
            &::after {
              top: 0;
              left: 3px;

              width: 5px;
              height: 8px;

              transform: rotate(45deg);

              //border: solid white;
              //border-width: 0 2px 2px 0;
              //border-top-width: medium;
              //border-right-width: medium;
              //border-bottom-width: medium;
              //border-left-width: medium;
            }
          }
        }
        a,
        button {
          position: relative;

          display: block;

          width: 100%;
          padding: 0;
          padding-left: 30px;

          text-align: left;
          text-transform: capitalize;

          border: none;
          background: none;
          span {
            font-weight: 500;
            line-height: 20px;

            position: absolute;
            top: 50%;
            right: 0;

            display: inline-block;

            width: 30px;
            height: 20px;

            transition: all 0.3s ease 0s;
            transform: translateY(-50%);
            text-align: center;

            color: #9a9a9a;
            border-radius: 50px;
            background-color: #eeeeee;
          }
          &:hover,
          &.active {
            span {
              color: #fff;
              background-color: $theme-color;
            }
            & > .checkmark {
              border-color: $theme-color;
              background-color: $theme-color;
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }

    &--blog {
      ul {
        li {
          .sidebar-widget-list-left {
            .checkmark {
              top: 5px;
            }
          }
        }
      }
    }
  }
  .sidebar-widget-tag {
    ul {
      li {
        display: inline-block;

        margin: 0 10px 15px 0;
        @media #{$lg-layout} {
          margin: 0 5px 15px 0;
        }
        a,
        button {
          line-height: 1;

          display: inline-block;

          padding: 9px 17px 12px;

          text-transform: capitalize;

          color: #424242;
          border: none;
          border-radius: 50px;
          background: none;
          background-color: #f6f6f6;
          @media #{$lg-layout} {
            padding: 9px 13px 12px;
          }
          &:hover,
          &.active {
            color: #fff;
            background-color: $theme-color;
          }
        }
      }
    }
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes show-in {
  0%{
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes show-out {
  0%{
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

.sidebar-widget-banner {
  background-color: #ffffff;
  width: 240px;
  height: 396px;
  margin-top: 30px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  @media #{$lg-layout} {
    &.fixed {
      position: fixed;
      top: 60px;
    }
  }
  @media #{$xl-layout} {
    &.fixed {
      position: fixed;
      top: 60px;
    }
  }
  @media #{$xxl-layout} {
    &.fixed {
      position: fixed;
      top: 60px;
    }
  }
  @media #{$md-layout} {
    &.fixed {
      position: relative;
    }
  }
  @media #{$xs-layout} {
    &.fixed {
      position: relative;
    }
  }
  @media #{$sm-layout} {
    &.fixed {
      position: relative;
    }
  }
  .banner-animation-in {
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    transition: 2s; /*Скорость перехода состояния элемента*/
    animation: show-in 2s 1; /* Указываем название анимации, её время и количество повторов*/
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    //animation-delay: 1s; /* Задержка перед началом */
  }
  .banner-animation-out {
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: 1s; /*Скорость перехода состояния элемента*/
    animation: show-out 1s 1; /* Указываем название анимации, её время и количество повторов*/
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    //animation-delay: 1s; /* Задержка перед началом */
  }
  .sidebar-widget-banner-image {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    background-size: cover;
    background-position: center;
  }
  h3.sidebar-widget-banner-title {
    font-size: 20px;
    margin: 20px 0 0 0;
    text-align: center;
  }
  h4.sidebar-widget-banner-subtitle {
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
  .sidebar-widget-banner-text {
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0 0 0;
    line-height: 14px;
    max-height: 70px;
    overflow-y: hidden;
    color: #000000;
  }
}



.pro-sidebar-search {
  .pro-sidebar-search-form {
    position: relative;
    input {
      font-size: 14px;

      height: 43px;
      padding: 2px 55px 2px 18px;

      color: #000;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }
    button {
      font-size: 20px;

      position: absolute;
      top: 50%;
      right: 0;

      padding: 0 15px;

      cursor: pointer;
      transition: all 0.3s ease 0s;
      transform: translateY(-50%);

      color: #000;
      border-width: medium medium medium 1px;
      border-style: none none none solid;
      border-color: #a1a5aa;
      border-image: none;
      background: transparent none repeat scroll 0 0;
      &:hover {
        color: $theme-color;
      }
    }
  }
}

.single-sidebar-blog {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin: 0 0 20px;
  padding: 0 0 20px;

  border-bottom: 1px solid #dee0e4;
  &:last-child {
    border-bottom: none;
  }
  .sidebar-blog-img {
    flex: 0 0 70px;

    margin: 0 20px 0 0;
    @media #{$lg-layout} {
      margin: 0 8px 0 0;
    }
    img {
      width: 100%;
    }
  }
  .sidebar-blog-content {
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;

      display: block;

      letter-spacing: 1px;
      text-transform: uppercase;

      color: #333;
    }
    h4 {
      font-size: 14px;

      margin: 5px 0 0;
    }
  }
}
