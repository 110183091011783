.logo_text {
  //height: 100%;
  //display: flex;
  //align-items: center;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: #000000;
}

.btn-home {
  padding: 19px 50px 21px;
  border: 1px solid #333;
}

.nordic-text {
  font-size: 50px;
  font-weight: 600;
}

.contact-info-wrap table {
  width: 100%;
  margin-bottom: 20px;
}

.contact-info-wrap td,
.contact-info-wrap th {
  padding: 10px;
}

.contact-info-wrap tr:not(:last-child),
.contact-info-wrap tr:not(:first-child) {
  border-bottom: 1px solid #ebebeb;
}

.backwards {
  margin: 10px 0 0 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backwards i {
  font-size: 10px;
  margin-right: 5px;
}

.messenger-icon {
  width: 15px;
  margin-right: 5px;
}

//.image-thumbnail img {
//  width: 135px;
//  //height: 180px;
//}
//
//.image-original img {
//  width: 600px;
//  height: 800px;
//}